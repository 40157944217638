import React from 'react'
import Link, { navigate as n } from 'gatsby-link'
import { buildQueryUrl, isMaintMode } from '../../utils/helperFn'

export const navigate = path =>
  isMaintMode() ? n(buildQueryUrl('', path, { bypass: 'true' })) : n(path)

const WrappedLink = ({ to, children, ...rest }) =>
  isMaintMode() ? (
    <Link to={buildQueryUrl('', to, { bypass: 'true' })} {...rest}>
      {children}
    </Link>
  ) : (
    <Link to={to} {...rest}>
      {children}
    </Link>
  )

export default WrappedLink
