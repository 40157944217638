import * as styles from './Home.module.css'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Title, Section, Box, Text } from '../../components/Core'
import Link from '../../components/Link'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const ContentCard = ({
  className,
  name,
  price,
  cents,
  subtitle,
  children,
  ...rest
}) => {
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  return (
    <Box
      bg={userDarkMode ? 'dark' : 'light'}
      border="1px solid"
      borderColor="border"
      borderRadius={10}
      className={`${className}`}
      {...rest}
    >
      <Link to="../pricing/">
        <Box textAlign={'center'} p="25px">
          <Title variant="card" className={styles.cardBlueTitle}>
            {name}
          </Title>
          <Text
            color={userDarkMode ? 'white' : 'black'}
            fontSize={'40px'}
            fontWeight={900}
            mt={3}
          >
            {price}
          </Text>
          <Text color={userDarkMode ? 'white' : 'black'} mb={3} variant="small">
            {cents}
          </Text>
          <Text
            textAlign={'center'}
            color={userDarkMode ? 'lightShade' : 'blackShade'}
            variant="small"
          >
            <strong>{subtitle}</strong>
            <br />
            {children}
          </Text>
        </Box>
      </Link>
    </Box>
  )
}

const Plans = () => {
  const { t } = useTranslation()
  // const userExchRate = useSelector(state => state.user.exchRate);
  // const currSign =  useSelector(state => state.user.currSign);
  // let proTraderPrice = userExchRate ? (18.66 * userExchRate).toFixed(2) : (18.66 * 1).toFixed(2);
  // let apiDevPrice = userExchRate ? (99.99 * userExchRate).toFixed(2) : (99.99 * 1).toFixed(2);
  let proTraderPrice = (18.66).toFixed(2)
  let apiDevPrice = (99.99).toFixed(2)
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  return (
    <>
      {/* <!-- Plans section --> */}
      <Section
        style={{
          background: userDarkMode
            ? 'black'
            : 'linear-gradient(180deg, #003D8B 0%, #185398 26.2%, #185398 71.76%, #3377C6 100%)',
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg="12" xl="12" className="text-center">
              <Title color="#FAFAFA" mb={[4, null, null, 5]}>
                {t('landing.upgradeAccount')}
              </Title>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg="5" className="mb-5">
              <Box
                css={`
                  &:focus {
                    outline: none;
                  }
                `}
              >
                <ContentCard
                  name={t('landing.proTrader')}
                  price={`us$ ${proTraderPrice.toLocaleString(undefined)}`}
                  cents={t('pricingPage.perMonth')}
                  subtitle={t('landing.levelUp')}
                >
                  {t('landing.unlimitedTrading')}
                  <br />
                  {t('landing.stopLimit')}
                  <br />
                  {t('landing.priceAlerts')}
                </ContentCard>
              </Box>
            </Col>
            <Col lg="5" className="mb-5">
              <Box
                css={`
                  &:focus {
                    outline: none;
                  }
                `}
              >
                <ContentCard
                  name={t('landing.apiDevPro')}
                  price={`us$ ${apiDevPrice.toLocaleString(undefined)}`}
                  cents={t('pricingPage.perMonth')}
                  subtitle={t('landing.forInstitutions')}
                >
                  {t('landing.entireCryptofeed')}
                  <span role="img" aria-label="TradeMark">
                    ™️
                  </span>{' '}
                  {t('landing.apiAcess')}
                  <br />
                  {t('landing.sdk')}
                  <br />
                  {t('landing.prioritySupport')}
                </ContentCard>
              </Box>
            </Col>

            <Col xs="12" className="text-center mt-3">
              <div
                data-aos="fade-down"
                data-aos-duration="800"
                data-aos-once="true"
                data-aos-delay="500"
              >
                <a
                  className={styles.aTagButton}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.coinigy.com/subscription/manage"
                >
                  {t('landing.upgradeButton')}
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default Plans
